
#loading {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    color: whitesmoke;
    -webkit-text-stroke: medium;
    text-align: center;
    size: 100px;
    font-size: 100px;
}
