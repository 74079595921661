
.card {
    background-color: black;
    border-radius: 100px 100px 50px 50px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 400px;
    height: 630px;
    min-width: 350px;
    min-height: 580px;
    box-shadow: 0px 7px 20px 0px;
}


a {
    text-decoration: none;
    color: #2f2424;
  }
  
h1 {
  border-bottom: black;
  border-bottom-style: solid;
  padding-bottom: 25px;
  border-color: #a3ad84;
  margin: 0px;
  padding-top: 21px;
}

.title {
  background-color: #e7e7d2;
}

p {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 17px;
  background-color: #e7e7d2;
  height: 100%;
  margin: 0px;
  padding-top: 20px;
}

@media screen and (max-width: 600px){
  p {
    font-size: 16px !important;
  }
}


/*
.card {
    background-color: black;
    border-radius: 100px 100px 30px 30px;
    color: aliceblue;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    
    min-height: 300px;
    width: 33vw;
    min-width: 400px;
}
    */