.App {
   display: flex;
   flex-direction: column;
   overflow-x: hidden;
   overflow-y: hidden;
   box-sizing: border-box;
   height: 100%;
   align-items: center;
   justify-content: flex-start;
   background-color: #2C3E50;
 }

 .main {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  align-items: center;
  justify-content: space-around;
 }

 .cardContainer {
   display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    height: 100%;
    align-items: center;
    padding: 0px 100px;
    column-gap: 100px;
    width: 90%;
    justify-content: center;
    border-style: solid;
    border-top-style: none;
    border-color: wheat;
    border-width: thin;
 }

 .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 10%;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 2px 20px black;
  background-color: #233140;

 }

 
 
 #name {
  border-style: none;
  font-style: italic;
  margin: 0px;
  padding: 0px;
  color: white;
 }

 @media screen and (max-width: 600px){

    .main {
      height: 85%;
    }
    #previousCard {
      position: absolute;
      left: 0;
      height: 510px;
      top: 270px;
      width: 50%;
      margin: 0px;
      opacity: 0;
      z-index: 100;
      transition: none !important;
    }

    #nextCard {
      position: absolute;
      right: 0;
      height: 510px;
      top: 270px;
      width: 50%;
      margin: 0px;
      opacity: 0;
      z-index: 100;
      transition: none !important;
    }

    .cardContainer {
      border-style: none;
    }

    
 }

 .footer {
   margin-top: 10px;
 }
 button {
   border-radius: 50%;
   width: 50px;
   height: 50px;
   margin: 10px;
   padding: 0px;
   border-style: none;
   box-shadow: 6px 5px 20px 0px #141313;
   background-color: #2C3E50;
   overflow: hidden;
 }

 button > svg {
  width: 50px;
  height: 50px;
 }

 button:hover {
  cursor: pointer;
 }
 
 h1 {
   text-align: center;
   text-decoration: none;
 }




/* .App {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    column-gap: 100px;
    padding: 0px 100px;
 }

 p {
    padding-left: 20px;
    padding-right: 20px;
 }

 h1 {
    text-align: center;
 }
    */